import React from 'react';
import previewImage from '../../../../assets/img/courses/kids/blockly-preview.png'

const OverviewPart = () => {

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>Coding Doodle - Creating with Programming</h4>
                    <p>Coding Doddle is designed for kids and gives the opportunity to explore ideas in Math and Science. We developed this coding language course as a way for kids to develop confidence and fluency with a piece of powerful, modern, and one-day ubiquitous technology.</p>
                    <p>Everyone may have the first experience with coding. The Google Blocky team designed the interactive program example for kids by "Coding for Carrots.". You need to program and help a furry friend across 6 levels in a quest to gather its favorite food by snapping together coding blocks based on the Scratch programming language for kids.</p>
                    <p>Let's give a try!</p>
                    <iframe src="https://www.google.com/logos/2017/logo17/logo17.html" width="100%" height="400" className='pt-20 pb-20'></iframe>
                    <p>In our Doodle course, we teach the students with the tools of EduBlocky and App Inventor, which are designed by the MIT Scratch and Google Blocky team with the similar concepts. The course will mainly cover the fundamental programming concepts and create their own interactive stories, games or applications in mobile and web development.</p>

                    {/* <ul className="student-list">
                        <li>23,564 Total Students</li>
                        <li><span className="theme_color">4.5</span> <span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span> (1254 Rating)</li>
                        <li>256 Reviews</li>
                    </ul> */}
                    <img src={previewImage} className='pt-20 pb-20'/>
                    <h3>What you’ll learn?</h3>
                    <ul className="review-list kids-style">
                        <li>Develop coding knowledge based on the fundamental concepts by creating variables, loops, functions, and different arguments.</li>    
                        <li>Create interactive games with code incorporating sprite animation, musical composition, synthesized sounds, special effects and more.</li>
                        <li>Learn the basic skills on how to construct the website with information.</li>
                    </ul>
                    
                    {/*
                        <h3>Requirements</h3>
                        <ul className="review-list kids-style">
                            <li>Phasellus enim magna, varius et commodo ut, ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo</li>
                            <li>Ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel.</li>
                            <li>Phasellus enim magna, varius et commodo ut.</li>
                            <li>Varius et commodo ut, ultricies vitae velit. Ut nulla tellus.</li>
                            <li>Phasellus enim magna, varius et commodo ut.</li>
                        </ul> */
                    }
                </div>
            </div>
        </div>
    );
}

export default OverviewPart;