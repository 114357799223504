import React from 'react';
import CourseDetailsPart from './CourseDetailsPart';
import ScrollToTop from '../../../../components/Common/ScrollTop';
import SEO from '../../../../components/SEO/SEO'

const CourseDetailsMain = (props) => {

    return (
        <React.Fragment>
            <SEO 
				title={`Kids puzzle programming course for beginners - Blockly`}
				description={`Code innovative ideas by Edublocky and App Inventor, allows kids to enjoy freedom in Coding Doodle environment`}
				keywords="消費卷,IT,程式,軟件,工程,幼兒教育,scratch,STEM,小朋友,blockly,technology,course,class,lesson,programming,software,website,mobile,mobile app,online,ecommerce,tech,coding,engineer"
				url={`https://proclasshub.com/course/kids/1000`}
				image="https://proclasshub.com/static/media/blockly-thumbnail.3b4b5be0.jpeg"
			/>
            {/* CourseDetails Start */}
            <CourseDetailsPart/>
            {/* CourseDetails End */}

			{/* scrolltop-start */}
			<ScrollToTop
				scrollClassName="scrollup orange-color"
			/>
			{/* scrolltop-end */}

        </React.Fragment>
    )
}

export default CourseDetailsMain;