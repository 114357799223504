import React from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const ApplicationPart = () => {
    let tab1 = "In-person Private Class",
    tab2 = "Online Private Class"

    const tabStyle = 'application-tabs tabs-box';

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>Find Your Best Fit</h4>
                    <div className="intro-info-tabs kids-style">
                        <Tabs>
                            <TabList className={tabStyle}>
                                <Tab>
                                    <button className="kids-style">{tab1}</button>
                                </Tab>
                                <Tab>
                                    <button className="kids-style">{tab2}</button>
                                </Tab>
                                {/* <Tab>
                                    <button className="adults-style">{tab3}</button>
                                </Tab> */}
                            </TabList>

                            <TabPanel>
                            <h6>{tab1}</h6>
                            <p>We have the private class which allows our instructor and student to teach and learn the best in 1:1 face to face experience.</p>
                            <div className="curriculum-index kids-style">
                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-5 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Beginner Module</h4>
                                        </div>
                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">4 sessions in 8 hours</span>
                                        </div>

                                        <p>We will go through each of the module with half part to experience the course.</p>

                                        <div>HK$ 5,850 <span className="red">Save 25%</span></div>
                                        <div className="black bold strikethrough">HK$ 7,800</div>
                                    </div>
                                    <div className="curriculum-row-item curriculum-row-right">
                                        <h5 className='strikethrough'>Starts on Dec 3</h5>
                                        <div><span className='strikethrough'>Every Saturday/Sunday</span></div>
                                        <div><span className='strikethrough'>10:00 - 18:00</span></div>
                                        <div><span className="red">FULL</span></div>
                                    </div>
                                    <br className="clear"/>
                                </div>

                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-5 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Comphensive Module</h4>
                                        </div>
                                        
                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">8 sessions in 16 hours</span>
                                        </div>

                                        <p>A full comphensive course with all the modules will be go through and demonstrate in the experience.</p>

                                        <div>HK$ 11,700 <span className="red">Save 25%</span></div>
                                        <div className="black bold strikethrough">HK$ 15,600</div>
                                    </div>

                                    <div className="curriculum-row-item curriculum-row-right">
                                        <h5 className='strikethrough'>Starts on Dec 3</h5>
                                        <div><span className='strikethrough'>Every Saturday/Sunday</span></div>
                                        <div><span className='strikethrough'>10:00 - 18:00</span></div>
                                        <div><span className="red">FULL</span></div>
                                    </div>
                                    <br className="clear"/>
                                </div>
                            </div> 
                            </TabPanel>

                            <TabPanel>
                            <h6>{tab2}</h6>
                            <p>We have the online class which allows our instructor and student to teach and learn the best in 1:1 face to face experience.</p>
                            <div className="curriculum-index kids-style">
                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-5 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Beginner Module</h4>
                                        </div>
                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">4 sessions in 8 hours</span>
                                        </div>

                                        <p>We will go through each of the module with half part to experience the course.</p>

                                        <div>HK$ 5,250 <span className="red">Save 25%</span></div>
                                        <div className="black bold strikethrough">HK$ 7,000</div>
                                    </div>
                                    <div className="curriculum-row-item curriculum-row-right">
                                        <h5 className='strikethrough'>Starts on Dec 3</h5>
                                        <div><span className='strikethrough'>Every Saturday/Sunday</span></div>
                                        <div><span className='strikethrough'>10:00 - 18:00</span></div>
                                        <div><span className="red">FULL</span></div>
                                    </div>
                                    <br className="clear"/>
                                </div>

                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-5 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Comphensive Module</h4>
                                        </div>
                                        
                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">8 sessions in 16 hours</span>
                                        </div>

                                        <p>A full comphensive course with all the modules will be go through and demonstrate in the experience.</p>

                                        <div>HK$ 10,500<span className="red">Save 25%</span></div>
                                        <div className="black bold strikethrough">HK$ 14,000</div>
                                    </div>

                                    <div className="curriculum-row-item curriculum-row-right">
                                        <h5 className='strikethrough'>Starts on Dec 3</h5>
                                        <div><span className='strikethrough'>Every Saturday/Sunday</span></div>
                                        <div><span className='strikethrough'>10:00 - 18:00</span></div>
                                        <div><span className="red">FULL</span></div>
                                    </div>
                                    <br className="clear"/>
                                </div>
                            </div> 
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApplicationPart;