import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Layout/Header/Header';
import FooterStyleTwo from '../components/Layout/Footer/FooterStyleTwo';
// import Newsletter from '../components/Common/Newsletter';
import ScrollToTop from '../components/Common/ScrollTop';
import OffWrap from '../components/Layout/Header/OffWrap';
import SiteBreadcrumb from '../components/Common/Breadcumb';
import SearchModal from '../components/Layout/Header/SearchModal';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

// Image
// import favIcon from '../assets/img/fav-orange.png';
import stickyLogo from '../assets/img/logo/logo.png';
import darkLogo from '../assets/img/logo/logo-dark2.png';
import SEO from '../components/SEO/SEO'

const Faq = () => {

    return (
        <React.Fragment>
            <SEO 
				title="FAQ"
				description="We are a group of people with the utmost enthusiasm to pass useful knowledge to all learners"
				keywords="消費卷,IT,程式,軟件,工程,幼兒教育,scratch,STEM,小朋友,technology,course,class,lesson,programming,software,website,mobile,mobile app,online,ecommerce,tech,coding,engineer"
				url="https://proclasshub.com/faq"
				image="https://ik.imagekit.io/ztlzy5otbl/Screenshot_2022-11-03_at_17.35.29.png"
			/>
            <OffWrap />
            <Header
                parentMenu='faq'
                headerNormalLogo={darkLogo}
                headerStickyLogo={stickyLogo}
                CanvasLogo={darkLogo}
                mobileNormalLogo={darkLogo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style3 modify expand-none"
                TopBarClass="topbar-area dark-parimary-bg"
                TopBar='enable'
                emailAddress='info@proclasshub.com'
                phoneNumber='+852 6412 3544'
            />

            {/* breadcrumb-area-start */}
            {/* <SiteBreadcrumb
                pageTitle="Faq"
                pageName="Faq"
                breadcrumbsImg={bannerbg}
            /> */}
            {/* breadcrumb-area-End */}

            {/* Faq Part Start */}
            <div className="rs-faq-part style1 pt-90 pb-100 md-pt-70 md-pb-80">
                <div className="container">
                    <div className="main-part innerpage">
                        <div className="faq-content mb-50 md-mb-30">
                            <div className="title mb-30 md-mb-14">
                                <h3 className="text-part">Course Advisement</h3>
                            </div>
                            <Accordion className="accordion-style1" preExpanded={'a'}>
                                <AccordionItem className="accordion-item" uuid="a">
                                    <AccordionItemHeading className="card-header">
                                        <AccordionItemButton className="card-link">
                                            Who should take the ProClasshub course?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body">
                                        Students join our online/face-to-face teaching courses for a variety of reasons without the limitations of backgrounds, professions, and age groups. With the ProClasshub courses, you can: 
                                        <li>Advance your skill-set or begin a career change or business journey.</li>
                                        <li>Take the next step in your formal education.</li>
                                        <li>Ease the transition before entering a full-time academic program.</li>
                                        <li>Enjoy learning a new topic.</li>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="accordion-item" uuid="b">
                                    <AccordionItemHeading className="card-header">
                                        <AccordionItemButton className="card-link">
                                            How do I select the online/face-to-face course?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body">
                                        Apart from searching the course by duration and availability in our course catalog page, you can also search by subject to ensure you find a course that reflects your interests. If you still do not sure about what course is right for you, please feel free to contact us about what you hope to learn. We can then provide some personalized course advisement.
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="accordion-item" uuid="c">
                                    <AccordionItemHeading className="card-header">
                                        <AccordionItemButton className="card-link">
                                            Can I take more than one course at a time?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body">
                                        If you are new to online learning, we recommend that you begin with one course. As our courses are offered frequently throughout the year, you can always come back and continue your studies with one or more topics.
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="accordion-item" uuid="d">
                                    <AccordionItemHeading className="card-header">
                                        <AccordionItemButton className="card-link">
                                            What should I do if I need to start the course late?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body">
                                        It is recommended that you begin your course promptly. If you do need to start the course a few days late, contact your instructor as soon as possible and the time can only be changed in 24 hours before the lesson starts.    
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>

                        <div className="faq-content mb-50 md-mb-30">
                            <div className="title mb-30 md-mb-14">
                                <h3 className="text-part">Course Learning Experience</h3>
                            </div>
                            <Accordion className="accordion-style1" preExpanded={'a'}>
                                <AccordionItem className="accordion-item" uuid="a">
                                    <AccordionItemHeading className="card-header">
                                        <AccordionItemButton className="card-link">
                                            How long is the course?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body">
                                        We currently offer 4-week and 6-week courses, which expect to spend 5-7 hours a week reviewing and completing the course material.
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="accordion-item" uuid="b">
                                    <AccordionItemHeading className="card-header">
                                        <AccordionItemButton className="card-link">
                                            How does Online Learning work?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body">
                                        Our course page provides more information on what the online experience is and how you can expect to interact with your course. 
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="accordion-item" uuid="c">
                                    <AccordionItemHeading className="card-header">
                                        <AccordionItemButton className="card-link">
                                            What should I do if I’m away for a few days?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body">
                                        Please inform your instructor. Make every effort to continue working on the course while you’re away. It is important that you contact your instructor as soon as possible to determine what, if any, arrangements can be made for your situation.
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="accordion-item" uuid="d">
                                    <AccordionItemHeading className="card-header">
                                        <AccordionItemButton className="card-link">
                                            Do I need specific equipment to take Online Courses?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body">
                                        Our Online Courses can be accessed on a mobile or tablet device, although we strongly encourage you to have access to a desktop or laptop computer and reliable internet connection for certain course components. Former students have told us it is easiest to read discussion threads and complete written assignments on a laptop or desktop computer.
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>  

                        <div className="faq-content mb-50 md-mb-30">
                            <div className="title mb-30 md-mb-14">
                                <h3 className="text-part">Operation and Payment</h3>
                            </div>
                            <Accordion className="accordion-style1" preExpanded={'a'}>
                                <AccordionItem className="accordion-item" uuid="a">
                                    <AccordionItemHeading className="card-header">
                                        <AccordionItemButton className="card-link">
                                            Can I change the class schedule?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body">
                                        Classes schedule can be changed regarding on the different course type. 
                                        <li>Private Course:</li>
                                        <div className="pl-20 pr-20">The time schedule can be renewed after every module is completed, and the course time cannot be changed in 24 hours before the lesson starts. The schedule will be allowed to re-arrange only one time in every module, or otherwise extra fee is needed. Please contact the CS for the details and available time slot. </div>
                                        <li>Bootcamp Course:</li>
                                        <div className="pl-20 pr-20">The course time cannot be changed in 24 hours before the lesson starts, and it will be allowed to re-arrange only one time in the entire course, or otherwise extra fee is needed. Please contact the CS for the details and available time slot.</div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="accordion-item" uuid="b">
                                    <AccordionItemHeading className="card-header">
                                        <AccordionItemButton className="card-link">
                                            How can I do the payment?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body">
                                        The payment installemnt will be divided into a few times according to the course modules. Please directly deposit the course fee into our company bank account, and send us the settlement reciept to reserve the course.
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="accordion-item" uuid="c">
                                    <AccordionItemHeading className="card-header">
                                        <AccordionItemButton className="card-link">
                                            How can I refund from the course fee?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="card-body">
                                        The course fee is not refundable either the student is absent from the class wihtout any early 24 hours notice or the lesson is attended. We can refund the course fee for the course that is yet to attend, but please be reminded that the first deposit payment is non-refundable.
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>                      
                    </div>
                </div>
            </div>
            {/* Faq Part End */}

            {/* <Newsletter
                sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
                titleClass="title mb-0 white-color"
            /> */}

            <FooterStyleTwo 
                footerTopClass='footer-top no-gap'
            />

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>

    );
}


export default Faq;