import React from 'react';

import SectionTitle from '../../components/Common/SectionTitle';

// About Image
import classroomImage from '../../assets/img/facilities/classroom_2.jpeg';

const AboutText = (props) => {

    return (
        <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">            
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
                        <div className="img-part">
                            <img className="about-main" src={classroomImage} alt="About Image" />
                        </div>
                    </div>
                    <div className="col-lg-6 pr-70 md-pr-14">
                        <div className="about-content">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-44 md-mb-0"
                                subtitleClass="sub-title"
                                subtitle="About ProClasshub"
                                titleClass="title mb-16"
                                title="We teach what we believe"
                                descClass="bold-text mb-22"
                                description="We are a group of people with the utmost enthusiasm to pass useful knowledge to all learners"
                                secondDescClass="desc"
                                secondDescription="Pragmatism is our educational principle for the capable learners to learn and grow in life. Our idea is simply to deliver applicable knowledge in the practical learning process, and our team will be dedicate to support the best."
                            />
                            {/* Section Title End */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutText;