import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';

// Service Image Icons
import startupImg from '../../assets/img/about/home7/startup.jpeg';
import image from '../../assets/img/facilities/common_area.jpeg';
// import aboutImg from '../../assets/img/about/home7/about.png';

const About = () => {

    return (
        <div id="rs-about" className="rs-about style7 pt-100 pb-100 md-pt-30 md-pb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 md-mb-30">
                        <div className="img-part">
                            <img src={image} alt="About Img" />
                        </div>
                    </div>
                    <div className="col-lg-6 pl-50 md-pl-14 col-md-12">
                        <SectionTitle
                            sectionClass="sec-title2"
                            subtitleClass="sub-title"
                            subtitle="What we aim"
                            titleClass="title mb-30"
                            title="Transforming education, professional and businesses"
                            descClass="desc mb-24"
                            description={
                                "We target to provide all learners the knowledge to realize their full potentials and develop strategies and approaches for their sustainable future." +
                                "We emphasis on foundational learning for basic literacy, and the skills for employment and entrepreneurship."
                            }
                        />
                        <div className="btn-part">
                            <Link className="readon banner-style uppercase" to="/about">About Us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;