import React from 'react';
import Slider from "react-slick";

import SectionTitle from '../../components/Common/SectionTitle';
import CourseSingleFive from '../../components/Courses/CourseSingleFive';

// Courses Image
import courseImg1 from '../../assets/img/courses/kids/blockly-thumbnail.jpeg';
import courseSwift from '../../assets/img/courses/adults/ios_15.jpeg';
import courseKotlin from '../../assets/img/courses/adults/course_android.jpeg';


const Courses = () => {
    const sliderSettings = {
        dots: true,
        centerMode: false,
        infinite: true,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    dots: false,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };

    return (
        <div className="rs-featured-courses pt-100 pb-150 md-pt-76 md-pb-140 sm-pb-70">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center"
                    subtitleClass="sub-title uppercase mb-10"
                    subtitle="Top Courses"
                    titleClass="title mb-30"
                    title="Popular Courses"
                />
                
                {/* <Slider {...sliderSettings}> */}
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <CourseSingleFive
                            courseId={1000}
                            courseType="kids"
                            courseClass= "courses-item"
                            courseSessions={4}
                            courseHours={8}
                            courseImg= {courseImg1}
                            courseTitle= "Coding Doodle - Creating with Programming"
                            courseCategory= "Mobile/Web Development"
                            btnText="Explore"
                        />
                    </div>

                    <div className="col-lg-4 col-md-6 mb-30">
                        <CourseSingleFive
                            courseId={2000}
                            courseType="adults"
                            courseClass= "courses-item"
                            courseSessions={4}
                            courseHours={8}
                            courseImg= {courseSwift}
                            courseTitle= "iOS & Swift - The Complete iOS App Development Bootcamp"
                            courseCategory= "Mobile App Development"
                            btnText="Explore"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <CourseSingleFive
                            courseId={2001}
                            courseType="adults"
                            courseClass= "courses-item"
                            courseSessions={4}
                            courseHours={8}
                            courseImg= {courseKotlin}
                            courseTitle= "The Complete Android & Kotlin Development Masterclass"
                            courseCategory= "Mobile App Develoment"
                            btnText="Explore"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Courses;